.board {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.board__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
}

.board__game {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.container__secretcode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 60px;
    background: linear-gradient(to bottom, #ff5101, black);
    z-index: 9;
}

.game__niveau {
    font-family: Changa;
    font-weight: 800;
    font-style: italic;
    font-size: 16px;
    margin: 10px 0;
    color: black;
    z-index: 99;

}

.text__secretcode {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    font-family: Changa;
    font-weight: 500;
    font-size: 30px;
    color: #ff5101;
    background-color: black;
    z-index: 99;
    animation: none;
}

.text__secretcode.animate {
    animation: secretcode 3s ease-in-out forwards;
}

@keyframes secretcode {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.rows {
    display: flex;
    flex-direction: column-reverse;
}

.show {
    display: flex;
}

.hide {
    display: none;
}

.btn__check {
    font-family: Changa;
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 20px;
    padding: 6px 40px;
    border: 4px #ff5101 solid;
    border-radius: 20px;
    background-color: white;
}

.rowNumber {
    font-family: Changa;
    font-weight: 800;
    font-size: 20px;
    color: #ff5101;
}

