@import url('https://fonts.googleapis.com/css2?family=Changa:wght@200..800&display=swap');

* {
    box-sizing: border-box;
    position: relative;

}

html,
body {
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', Arial, sans-serif;
    background-color: #494444;
}

a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}