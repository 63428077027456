.pause {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: wheat;
    z-index: 9999;
}

.pause svg {
    width: 100px;
    height: 100px;
    fill: black;
    stroke: #ff5101;
    stroke-width: 4px; 
    margin: 60px 0;
    animation: pause 2s linear infinite;
}

@keyframes pause {
    0% {
        scale: 1;
    }
    50% {
        scale: 1.4;
    }
    100% {
        scale: 1;
    }
}

.pause__text {
    font-family: Changa;
    font-weight: 800;
    font-size: 24px;
    margin: 30px 0 0 0;
}

.container__pause--svg svg:first-child {
    margin-right: 4px;
}

.pause__btns {
    display: flex;
    flex-direction: column-reverse;
}

.pause__btn {
    font-family: Changa;
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 30px;
    padding: 4px 20px;
    border: 4px #ff5101 solid;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
}