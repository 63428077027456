.home {
    display: flex;
    width: 100%;
    justify-content: center;
    border: 10px black solid;
    border-radius: 20px;
    background-color: wheat;
}

.home__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.game__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 3%;
    margin-top: 40px;
}

.description__text {
    font-family: Changa;
    margin: 0;
    line-height: 1.2;
    margin-bottom: 20px;
}

.game__niveau label {
    font-family: Changa;
    font-weight: 800;
    font-size: 16px;
    margin-right: 10px;
}

#niveau {
    font-family: Changa;
    font-weight: 500;
    font-size: 14px;
    padding: 4px 10px;
    border: 4px #ff5101 solid;
    outline: none;
    border-radius: 20px;
}

.game__niveau option {
    font-family: Changa;
    font-weight: 500;
    font-size: 14px;
}

.game__button {
    font-family: Changa;
    font-weight: 800;
    font-size: 16px;
    margin: 30px 0;
    padding: 10px 20px;
    border: 4px #ff5101 solid;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
}