.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: wheat;
    z-index: 9999;
}

.modal__btn--close {
    font-family: Changa;
    font-weight: 800;
    font-size: 16px;
    padding: 4px 20px;
    border: 4px #ff5101 solid;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
}

.modal__rules {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Changa;
    font-weight: 400;
    font-size: 14px;
    margin: 0 20px;
}

.modal__section {
    margin-bottom: 20px;
}

.modal__title {
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 20px;
    text-decoration: underline;
}

.modal__text {
    
    text-align: center;
    margin: 0;
}