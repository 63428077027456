.row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.row.inactive {
    pointer-events: none;
}

.feedback {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2px;
    row-gap: 4px;
    position: relative;
}

.feedback__dot--colors {
    display: flex;
}

.slotSelector {
    display: flex;
    margin: 0 20px;
}

.btnRowCheck {
    position: absolute;
    top: -4px;
    left: -2px;
    width: 100%;
    height: 30px;
}

.btn__ok {
    font-family: Changa;
    font-weight: 800;
    font-size: 16px;
    width: 100%;
    height: 100%;
    background-color: black;
    border: none;
    border-radius: 4px;
    animation: btnCheck 1s ease-in-out forwards;
    cursor: pointer;
  }

  .btn__ok svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    fill: #ff5101;
    z-index: 999;
  }

  @keyframes btnCheck {
    0% {
        transform: translateX(300%);
    }
    100% {
        transform: translateX(0);
    }
  }