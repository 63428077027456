.slot {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 10px;
    background-color: wheat;
    border: 2px solid black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), 0 -4px 8px rgba(255, 255, 255, 0.4) inset;
}
  
.slot.active {
    animation: slot 1s infinite;
    border: black dashed;
}  
  
@keyframes slot {
    0% {
        scale: 1.1;
    }
    50% {
        scale: 1;
    }
    100% {
        scale: 1.1;
    }
}