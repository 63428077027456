  .animationBomb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.invisible {
      visibility: hidden;
    }
  }

  .animation__svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    fill: black;
    stroke: #ff5101;
    stroke-width: 4px; 
    opacity: 0;
    animation: svg 4s linear;
    animation-delay: 86s;
  }

  @keyframes svg {
    0% {
        z-index: 1;
        opacity: 0;
    }
    75% {
        z-index: 1;
        scale: 6;
        opacity: 1;
        top: 90%;
        left: 114%;
    }
    90% {
        z-index: 1;
        scale: 6;
        opacity: 1;
        top: 90%;
        left: 114%;
    }
    100% {
        z-index: 1;
        scale: 1;
    }
  }
  
  .top,
  .bottom,
  .left,
  .right {
    position: absolute;
    background: #ff5101;
    border-radius: 20px;
  }

  .bottom {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 8px;
    animation: bottom 21.5s linear forwards;
    animation-delay: 64.5s;
  }

  .bottom__meche {
    content: "";
    position: absolute;
    bottom: 0;
    width: 8px;
    height: 8px;
    border-radius: 20px;
    opacity: 0;
    background-color: yellow;
    animation: meche 0.2s linear infinite;
    animation-delay: 64.5s;
  }

  @keyframes bottom {
    0% {
      width: 100%;
    }
    100% {
        width: 0%;
    }
  }

  .right {
    top: 0;
    right: 0;
    width: 8px;
    height: 100%;
    animation: right 21.5s linear forwards;
  }

  .right__meche {
    content: "";
    position: absolute;
    bottom: 0;
    width: 8px;
    height: 8px;
    border-radius: 20px;
    opacity: 0;
    background-color: yellow;
    animation: meche 0.2s linear infinite;
  }

  @keyframes right {
    0% {
        height: 100%;
    }
    100% {
        height: 0%;
    }
  }

  @keyframes meche {
   
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }

  .top {
    top: 0;
    left: 0;
    height: 8px;
    width: 100%;
    animation: top 21.5s linear forwards;
    animation-delay: 21.5s;
  }

  .top__meche {
    content: "";
    position: absolute;
    right: 0;
    width: 8px;
    height: 8px;
    border-radius: 20px;
    background-color: yellow;
    animation: meche 0.2s linear infinite;
    animation-delay: 21.5s;
  }

  @keyframes top {
    0% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
  }

  .left {
    bottom: 0;
    left: 0;
    width: 8px;
    height: 100%;
    animation: left 21.5s linear forwards;
    animation-delay: 43s;
  }

  .left__meche {
    content: "";
    position: absolute;
    top: 0;
    width: 8px;
    height: 8px;
    border-radius: 20px;
    opacity: 0;
    background-color: yellow;
    animation: meche 0.2s linear infinite;
    animation-delay: 43s;
  }

  @keyframes left {
    0% {
        height: 100%;
    }
    100% {
        height: 0%;
    }
  }