.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 40px;
    font-size: 14px;
    background-color: black;
    color: #ff5101;
}