.dot {
    display: flex;
    border: 2px #ff5101 solid;
    width: 10px;
    height: 10px; 
    border-radius: 50%; 
    display: inline-block;
    margin-right: 5px;
}

.black-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: black;
    border: none
}

.white-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: white;
    border: 1px black solid;
}