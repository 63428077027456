.color-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 10px 0 20px 0;
  }
  
  .color-option {
    width: 40px;
    height: 40px;
    border: 2px black solid;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 2%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), 0 -4px 8px rgba(255, 255, 255, 0.4) inset;

  }

  .colorsPalette8 {
    margin: 0 1%;
  }